var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Result", {
    attrs: { data: _vm.results, isHost: _vm.isHost, mission: _vm.mission },
    on: {
      onIncreaseTotalScore: function (ref) {
        var team = ref.team
        var score = ref.score

        return _vm.increaseTotalScore(team, score)
      },
      onDecreaseTotalScore: function (ref) {
        var team = ref.team
        var score = ref.score

        return _vm.decreaseTotalScore(team, score)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }