var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "generic-result",
      staticStyle: { "align-items": "flex-start" },
      attrs: { "d-flex": "" },
    },
    [
      _c(
        "v-layout",
        {
          ref: "panel",
          staticStyle: { "min-height": "100%" },
          attrs: { column: "", "justify-center": "" },
        },
        [
          _vm._l(_vm.dataMutable, function (team, index) {
            return [
              _c(
                "div",
                {
                  key: "team-result-" + team.id,
                  staticClass: "result-item",
                  class: { "result-item--top": team.top },
                },
                [
                  _c("div", { staticClass: "result-item__rank" }, [
                    _vm._v(" " + _vm._s(index + 1) + " "),
                  ]),
                  _c(
                    "v-icon",
                    { staticClass: "result-item__icon", attrs: { dark: "" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(team.icon || "radio_button_unchecked") +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "result-item__name" }, [
                    _vm._v(" " + _vm._s(team.name) + " "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "result-item__mission-score",
                      class: { pointer: _vm.isHost },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(team.score > 0 ? "+" + team.score : 0)
                      ),
                      _c("span", { staticClass: "result-item__pts" }, [
                        _vm._v("PTS"),
                      ]),
                    ]
                  ),
                  !_vm.mission.showMissionResultsOnly
                    ? _c(
                        "div",
                        {
                          staticClass: "result-item__total-score",
                          class: { pointer: _vm.isHost },
                          on: {
                            click: [
                              function ($event) {
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                ) {
                                  return null
                                }
                                return _vm.onIncreaseTotalScore(team)
                              },
                              function ($event) {
                                if (!$event.altKey) {
                                  return null
                                }
                                return _vm.onDecreaseTotalScore(team)
                              },
                              function ($event) {
                                if (!$event.shiftKey) {
                                  return null
                                }
                                return _vm.onDecreaseTotalScore(team)
                              },
                            ],
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(team.totalScore)),
                          _c("span", { staticClass: "result-item__pts" }, [
                            _vm._v("PTS"),
                          ]),
                        ]
                      )
                    : _c("div", { staticStyle: { width: "20px" } }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }