<template>
  <Result
    :data="results"
    :isHost="isHost"
    :mission="mission"
    @onIncreaseTotalScore="({ team, score }) => increaseTotalScore(team, score)"
    @onDecreaseTotalScore="({ team, score }) => decreaseTotalScore(team, score)"
  />
</template>

<script>
import { toRefs } from "@vue/composition-api"

import User from "@shared/User"
import Team from "@shared/Team"

import useResults from "@/use/useResults"

import Result from "./Result.vue"

export default {
  name: "ResultContainer",
  props: {
    mission: Object
  },
  components: {
    Result
  },
  setup(props) {
    const { mission } = toRefs(props)
    const { results } = useResults(mission)
    return { results }
  },
  methods: {
    increaseTotalScore(team) {
      if (!this.isHost) return
      const obj = {}
      const num = team.totalScore + parseInt(this.mission?.points / 2)
      if (num > 1000000) return
      if (isNaN(num)) return
      obj.teamScore = num
      obj.teamID = team.id
      this.$store.dispatch("setTeamScore", obj)
    },
    decreaseTotalScore(team) {
      if (!this.isHost) return
      const obj = {}
      const num = team.totalScore - parseInt(this.mission?.points / 2)
      if (num < 0) return
      if (isNaN(num)) return
      obj.teamScore = num
      obj.teamID = team.id
      this.$store.dispatch("setTeamScore", obj)
    }
  },
  computed: {
    isHost() {
      return User.isHost(this.$store.getters["auth/user"])
    }
  }
}
</script>
