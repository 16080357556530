<template>
  <v-flex d-flex style="align-items: flex-start" class="generic-result">
    <v-layout ref="panel" column justify-center style="min-height: 100%">
      <template v-for="(team, index) in dataMutable">
        <div
          class="result-item"
          :class="{ 'result-item--top': team.top }"
          :key="'team-result-' + team.id"
        >
          <div class="result-item__rank">
            {{ index + 1 }}
          </div>
          <v-icon dark class="result-item__icon">
            {{ team.icon || "radio_button_unchecked" }}
          </v-icon>
          <div class="result-item__name">
            {{ team.name }}
          </div>
          <div class="result-item__mission-score" :class="{ pointer: isHost }">
            {{ team.score > 0 ? "+" + team.score : 0
            }}<span class="result-item__pts">PTS</span>
          </div>
          <div
            v-if="!mission.showMissionResultsOnly"
            class="result-item__total-score"
            :class="{ pointer: isHost }"
            @click.exact="onIncreaseTotalScore(team)"
            @click.alt="onDecreaseTotalScore(team)"
            @click.shift="onDecreaseTotalScore(team)"
          >
            {{ team.totalScore }}<span class="result-item__pts">PTS</span>
          </div>
          <div v-else style="width: 20px"></div>
        </div>
      </template>
    </v-layout>
  </v-flex>
</template>

<script>
import { Power2, TweenMax } from "gsap/TweenMax"
import { mapGetters } from "vuex"
import _ from "lodash"

import { db } from "@/firebase"
import User from "@shared/User"

export default {
  name: "Result",
  props: {
    data: Array,
    isHost: Boolean
  },
  data() {
    return {
      dataMutable: [],
      identifier: "results"
    }
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission"
    }),
    sessionId() {
      return this.$store.state.auth.clientID
    },
    viewer() {
      return this.$store.state.auth.user
    },
    isViewerPresenter() {
      return User.isPresenter(this.viewer)
    },
    db() {
      return db.auxiliary(this.sessionId)
    },
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    orgId() {
      return this.$store.getters.orgID
    },
    gameId() {
      return this.$store.getters.gameID
    },
    scrollPercentRef() {
      return this.db.ref(
        `org/${this.orgId}/game/${this.gameId}/state/${this.identifier}/scrollPercent`
      )
    }
  },
  methods: {
    onScrollPercentSnapshot(snapshot) {
      const percent = parseInt(snapshot.val()) || 0
      const scrollable = this.$el
      const scrollableRect = scrollable.getBoundingClientRect()
      const scrollableHeight = scrollableRect.height
      const list = this.$refs.panel
      const listRect = list.getBoundingClientRect()
      const listHeight = listRect.height
      const value = Math.round(
        (listHeight - scrollableHeight) * (percent / 100)
      )

      this.$el?.scrollTo({ top: value })
    },
    handleScroll: _.throttle(function (e) {
      const scrollable = e.target
      const scrollTop = scrollable.scrollTop
      const scrollableRect = scrollable.getBoundingClientRect()
      const scrollableHeight = scrollableRect.height
      const list = this.$refs.panel
      const listRect = list.getBoundingClientRect()
      const listHeight = listRect.height
      const percent = Math.round(
        (scrollTop / (listHeight - scrollableHeight)) * 100
      )

      this.scrollPercentRef?.set(percent)
    }, 100),
    onIncreaseTotalScore(team) {
      if (this.isHost) this.$emit("onIncreaseTotalScore", { team, score: 30 })
    },
    onDecreaseTotalScore(team) {
      if (this.isHost) this.$emit("onDecreaseTotalScore", { team, score: 30 })
    },
    setScore({ score, totalScore }, target) {
      target.score = score
      target.totalScore = totalScore
    },
    animateScore(to, stagger = false, staggerDelay = 0) {
      this.dataMutable.forEach((object, index) => {
        const target = to.find(obj => obj.id === object.id)
        if (!target) return
        const { score, totalScore } = object
        const obj = { score, totalScore }

        const onComplete = () => {
          if (index === to.length - 1) {
            this.dataMutable = to.map(team => ({ ...team }))

            const [firstResult, ...restResults] = this.dataMutable

            if (
              restResults.some(item => item.totalScore < firstResult.totalScore)
            ) {
              // the top team is always green
              this.dataMutable[0].top = true
              // to ensure that teams with the same
              // number of points and leading have
              // green color
              if (this.dataMutable.length > 1) {
                for (let i = 1; i < this.dataMutable.length; i++) {
                  if (
                    this.dataMutable[i].totalScore === firstResult.totalScore
                  ) {
                    this.dataMutable[i].top = true
                  }
                }
              }
            }
          }
        }

        TweenMax.to(obj, 1.3, {
          score: target.score,
          totalScore: target.totalScore,
          roundProps: "score,totalScore",
          onUpdate: this.setScore,
          ease: Power2.easeInOut,
          delay: stagger ? staggerDelay * index : 0,
          onUpdateParams: [obj, object],
          onComplete
        })
      })
    }
  },
  watch: {
    scrollPercentRef: {
      handler(newValue, oldValue) {
        if (!this.isViewerPresenter) return
        oldValue?.off("value", this.onScrollPercentSnapshot)
        newValue?.on("value", this.onScrollPercentSnapshot)
      },
      immediate: true
    },
    data: {
      handler: function (newValue) {
        console.log(newValue)
        this.animateScore(newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.dataMutable = this.data.map(team => ({
      ...team,
      score: 0,
      totalScore: 0
    }))

    // $nextTick because the component is wrapped
    // into <transition>
    this.$nextTick(() => {
      if (this.isViewerHostLike)
        this.$el.addEventListener("scroll", this.handleScroll)

      const items = this.$el.getElementsByClassName("result-item")

      TweenMax.staggerFrom(
        items,
        0.5,
        {
          y: 50,
          opacity: 0,
          delay: 0.3
        },
        0.2
      )

      setTimeout(() => this.animateScore(this.data, true, 0.05), 5)
    })
  },
  beforeDestroy() {
    if (this.isViewerHostLike)
      this.$el?.removeEventListener("scroll", this.handleScroll)
  }
}
</script>

<style lang="scss">
.generic-result {
  overflow: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .generic-result-scroll {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .pointer {
    cursor: pointer;
  }
}

.result-item {
  $block: &;
  background-image: radial-gradient(
    137.87% 137.87% at 49.24% 9.52%,
    #2c2c2c 0%,
    #000000 100%
  );
  background-color: #1d1d1d;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: bold;
  align-items: center;
  height: 50px;
  color: $color-white;
  padding-left: 18px;
  font-size: 28px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &--top {
    color: #00e599;
  }

  &__rank {
    margin-right: 16px;
  }

  &__icon {
    font-size: 90%;

    #{$block}--top & {
      color: #00e599;
    }
  }

  &__name {
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__mission-score {
    margin-left: auto;
    color: #b6d6ff;
    padding-right: 8px;
  }

  &__total-score {
    width: 100px;
    text-align: center;
  }

  &__pts {
    font-size: 60%;
  }
}
</style>
